import { errorsCodes } from 'common/errors';
import { toast } from 'react-toastify';
import { Tooltip } from "react-bootstrap";

export const utils = {
  getErrorString: (error) => {
    let textError = '';
    const arr = Object.values(error.response.data).flat();
    for (let i = 0; i < arr.length; i += 1) {
      textError += `${arr[i]}\n`;
    }
    return textError;
  },
  showErrorToast: (error) => {
    let errorString = '';
    let status = error?.response?.status;

    if (status) {
      errorString += `Status ${status}: `;
    }

    if (status === errorsCodes.SERVER_ERROR) {
      errorString += error?.response?.statusText;
    }

    if (error.response?.data) {
      errorString += utils.getErrorString(error);
    } else {
      errorString += error.message ? error.message : error;
    }

    toast.error(errorString);
  },
  toArrayMapping: (data, key) => {
    let result = {};
    data.forEach((item) => {
      const objKey = item[key]?.id
      if (objKey) {
        objKey in result ? result[objKey].push(item) : result[objKey] = [item];
      }
    });
    return result
  },
  toArrayMappingById: (data) => {
    return data.reduce((dict, item) => {
      dict[item.id] = item;
      return dict;
    }, {});
  },
  to12HourFormat: (time) => {
    const dateObject = new Date("1970-01-01T" + time + "Z");
    if (isNaN(dateObject.getTime())) {
      return null;
    }
    return dateObject.toLocaleTimeString("en-US", {
      timeZone: "UTC",
      hour12: true,
      hour: "2-digit",
      minute: "numeric",
    });
  },
  closedDays: (closedDates) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const lastDateOfWeek = new Date(currentDate);
    lastDateOfWeek.setDate(lastDateOfWeek.getDate() + 6);
    lastDateOfWeek.setHours(0, 0, 0, 0);

    const closedDays = [];

    closedDates.forEach((closedDate) => {
      const date = new Date(closedDate.date);
      date.setHours(0, 0, 0, 0);
      if (date >= currentDate && date <= lastDateOfWeek) {
        closedDays.push(date.getDay());
      }
    });
    return closedDays;
  },
  formatCounter: (time) => {
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time - minutes * 60);

    if (minutes < 10) minutes = '0' + minutes;
    if (seconds < 10) seconds = '0' + seconds;

    return minutes + ':' + seconds;
  },
  isOpen247: (schedules) => {
    return schedules?.every(schedule =>
      schedule &&
      schedule.opening_time === '00:00:00' &&
      schedule.closing_time === '23:59:00'
    );
  },
  scrollWindowToTop: () => {
    window.scroll({top: 0});
  },
  getParamFromUrl: (url, key) => {
    if (!url) {
      return null
    }
    const urlObj = new URL(url);
    const pageParam = urlObj.searchParams.get(key);
    return pageParam ? parseInt(pageParam) : null;
  },
  getAddressString: (address) => {
    let addressString = "";
    if (address.plot) {
      addressString = "House # " + address.plot;
    }
    if (address.street) {
      let street = address.street.toLowerCase();
      let streetKeywords = ["street", "lane", "road", "boulevard", "avenue", "shahra"];
      let containsStreetKeyword = streetKeywords.some(keyword => street.includes(keyword));

      if (!containsStreetKeyword) {
        addressString += ", Street # " + address.street;
      } else {
        addressString += ", " + address.street;
      }
    }
    if (address.block) {
      addressString = addressString + ", " + address.block;
    }
    return addressString;
  },
  getTimeStamp: (currentDate, hours, minutes, seconds) => {
    const day = new Date(currentDate);
    day.setHours(
      parseInt(hours, 10),
      parseInt(minutes, 10),
      parseInt(seconds, 10)
    );
    return day.getTime();
  },
  parseAddress: (address) => {
    let street = "";
    let block = "";

    const houseStreetRegex = /House # (\S+),/;
    const houseStreetMatch = address.match(houseStreetRegex);
    const house = houseStreetMatch?.[1] || null;

    const streetRegex = /(?:Street\s*#?\s*(.*?))(?:,|$)/i;
    let match = address.match(streetRegex);
    if (match?.length > 1) {
      street = match[1];
    }
    
    const blockRegex = /(?:House #|Street #)?\s*[^,]+,\s*(.*)/;
    match = address.match(blockRegex);
    
    if (match && match[1]) {
      block = match[1].replace(streetRegex, '').trim();
    }

    return {
      house: house,
      street: street,
      block: block,
    }
  },
  getReviewLabel: (count) => {
    return count == 1 ? "Review" : "Reviews";
  },
  updateRating: (avgRating, reviewsCount, newRating, oldRating = null) => {
    let updatedAvgRating;

    if (oldRating !== null) {
      const adjustedRatingSum = ((avgRating * reviewsCount) - oldRating) + newRating;
      updatedAvgRating = adjustedRatingSum / reviewsCount;
    } else {
      const updatedRatingSum = (avgRating * reviewsCount) + newRating;
      updatedAvgRating = updatedRatingSum / (reviewsCount + 1);
    }
    return Math.round(updatedAvgRating * 10) / 10;
  },
  renderTooltip: (option) => (
    <Tooltip id="button-tooltip">{option} is not offered.</Tooltip>
  ),
  calculateStat: (num) => {
    return num - Math.floor((num * 5) / 100);
  },
  isShopClosed: (schedules) => {
    const now = new Date();
    const currentDay = now.getDay();
    const currentTime = now.toTimeString().slice(0, 8);
    const todaySchedule = schedules?.find(schedule => schedule.day === currentDay);

    if (!todaySchedule) {
      return false;
    }

    const isOpen = todaySchedule.time_slots.some(slot => {
      if (slot.opening_time <= slot.closing_time) {
          return currentTime >= slot.opening_time && currentTime <= slot.closing_time;
      } else {
          // Special case: opening time is later in the day than closing time (crosses midnight)
          return currentTime >= slot.opening_time || currentTime <= slot.closing_time;
      }
    });
    return !isOpen;
}
};

